import React from 'react'

const Dot = ({ numberOfNotifications = '' }) => {
  const width = numberOfNotifications > 9 ? '30px' : '20px'
  return (
    <div
      className={`${numberOfNotifications > 9 ?'rounded-pill':'rounded-circle'} text position-absolute d-flex justify-content-center `}
      style={{
        width,
        height: '20px',
        backgroundColor: '#e41e3f',
        fontSize: '12px',
        fontWeight:'500',
        top: '-5px',
        left: '30px',
      }}
    >
      <p className='m-auto text-white'>
        {numberOfNotifications > 9 ? '9+' : numberOfNotifications}
      </p>
    </div>
  )
}

export default Dot

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  authToken: null,
  profileType: null,
  email: null,
  wallet: null,
  tokens: 0,
  zhxPrice: 0,
  profile: null,
}

export const tokenSlice = createSlice({
  name: 'tokenUpdate',
  initialState,
  reducers: {
    reset: () => initialState,
    updateAuthToken: (state, action) => {
      state.authToken = action.payload
    },
    updateProfileType: (state, action) => {
      state.profileType = action.payload
    },
    updateEmail: (state, action) => {
      state.email = action.payload
    },
    updateWalletAdd: (state, action) => {
      state.wallet = action.payload
    },
    updateZhxTokens: (state, action) => {
      state.tokens = action.payload
    },
    updateZhxPrice: (state, action) => {
      state.zhxPrice = action.payload
    },
    updateProfile: (state, action) => {
      state.profile = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateAuthToken,
  updateProfileType,
  updateEmail,
  updateWalletAdd,
  updateZhxTokens,
  updateZhxPrice,
  updateProfile,
  reset
} = tokenSlice.actions

export default tokenSlice.reducer
import React, { useState } from 'react'
import { UserService } from '../../../http-services/client/user'
import { Modal } from 'react-bootstrap'
import addPic from '../../../assests/images/add-pic.svg'
import * as utils from '../../../utils/utils'
import $ from 'jquery'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfile } from '../../../slices/tokenSlice'

const EditProfileModal = props => {
  const httpUser = new UserService()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [designation, setDesignation] = useState('')
  // const [user, setUser] = useState(null);
  const [profilePic, setProfilePic] = useState(null)
  const authToken = useSelector(state => state.authToken)
  const wallet = useSelector(state => state.wallet)
  const dispatch = useDispatch()

  const onContinueClick = async () => {
    try {
      let formData = new FormData()

      formData.append(
        email ? 'email' : 'metamaskAddress',
        email ? email : wallet
      )
      formData.append('first_name', firstName)
      formData.append('last_name', lastName)
      formData.append('email', email)
      formData.append('designation', designation)
      // formData.append("phone", "");
      formData.append('image', profilePic)

      if (authToken) {
        var response = await httpUser.updateUser(formData, authToken)
        if (response.data) {
          utils.showSuccessAlert(
            'Success',
            'Details updated successfully',
            'success'
          )
          setFirstName('')
          setLastName('')
          setEmail('')
          setDesignation('')
          props.toggleEditProfileModal()

          const metamaskOrEmail = email ? email : wallet
          const profile = await utils.getUserProfile(authToken,metamaskOrEmail)
          dispatch(updateProfile(profile))
        }
      }
    } catch (error) {
      utils.showErrorAlert('Oops', 'Something went wrong', 'error')
      console.log('ERR: ', error.message)
    }
  }
  const handleFileChange = e => {
    setProfilePic(e.target.files[0])
  }
  const handleImagUpload = () => {
    $('#files').trigger('click')
  }
  const renderEditProfileForm = () => {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-md-12' style={{ padding: '5px' }}>
            <input
              type='file'
              style={{ display: 'none' }}
              id='files'
              onChange={handleFileChange}
              accept='image/jpeg,image/jpg, image/png'
            />
            <div
              style={{
                height: '60px',
                width: '60px',
                borderRadius: '100px',
                backgroundColor: '#E0E0E0',
                display: 'inline-block',
                position: 'relative',
              }}
              onClick={handleImagUpload}
            >
              <img
                src={addPic}
                style={{
                  display: 'block',
                  margin: 'auto',
                  marginTop: '10px',
                  height: '40px',
                  width: '40px',
                  borderRadius: '100px',
                }}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6' style={{ padding: '5px' }}>
            <input
              type='text'
              className='form-control'
              name='firstName'
              placeholder='First Name*'
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </div>
          <div className='col-md-6' style={{ padding: '5px' }}>
            <input
              type='text'
              className='form-control'
              name='lastName'
              value={lastName}
              placeholder='Last Name*'
              onChange={e => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12' style={{ padding: '5px' }}>
            <input
              type='email'
              className='form-control'
              name='email'
              value={email}
              placeholder='Email'
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className='col-md-12' style={{ padding: '5px' }}>
            <input
              type='text'
              className='form-control'
              name='designation'
              value={designation}
              placeholder='Designation'
              onChange={e => setDesignation(e.target.value)}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <button
              className='btn btn-continue-profile'
              onClick={onContinueClick}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    )
  }
  return (
    <Modal
      show={props.showEditProfileModal}
      size='sm'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          props.toggleEditProfileModal()
        }}
      >
        <Modal.Title id='contained-modal-title-vcenter'>
          Edit Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderEditProfileForm()}</Modal.Body>
      {/* <Modal.Footer>
        {showEditProfileForm ? (
          <p className="edit-profile-modal-footer-text">
            You can input your email to receive notification and other
            information related your project request.
          </p>
        ) : (
          <button
            className="btn btn-edit-profile"
            onClick={toggleEditProfileForm}
          >
            Edit Profile
          </button>
        )}
      </Modal.Footer> */}
    </Modal>
  )
}

export default EditProfileModal

import React from 'react'
import { useHistory } from 'react-router'
import { Card } from 'react-bootstrap'
import './project.css'
import moment from 'moment'
import * as utils from '../../../utils/utils'
import ProjectStatus from '../../shared/project-status/ProjectStatus'
import { SubmissionService } from '../../../http-services/client/submission'
import { useSelector } from 'react-redux'

const ProjectCard = props => {
  const httpSubmission = new SubmissionService()
  const history = useHistory()
  const authToken = useSelector(state => state.authToken)
  const zhxPrice = useSelector(state => state.zhxPrice)
  const profileType = useSelector(state => state.profileType)

  const handleProjectClick = async (id, status) => {
    let isServiceprovider = false
    if (profileType === 'client') {
      let path = `/projects/${id}/details`
      history.push(path)
    } else if (profileType === 'service_provider') {
      let path = ''
      isServiceprovider = true
      await httpSubmission
        .previewSubmission(
          props.project.submissionid,
          authToken,
          profileType === 'client' ? false : true
        )
        .then(res => {
          const status = res.data.result.submissionDetail.status
          if (status === '1') {
            path = `/feeds/${id}/details`
          } else {
            path = `/offer/submission/${props.project.submissionid}/project/${props.project.id}/details`
          }
          history.push(path)
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <Card className='project-card '>
      <Card.Body className='cardBody'>
        <Card.Title className='project-card-title'>
          {/* {props.notification === 1 && (
            <div style={{ float: "right" }}>
              <Dot />
            </div>
          )} */}
          <div className='row'>
            <div className='col-lg-9 d-flex justify-content-between'>
              <p
                className='primary-font-color project-title'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleProjectClick(props.project.id, props.project.status)
                }}
              >
                {props.project.title}
              </p>
              <h4
                style={{ float: 'right', color: '#98A5AF' }}
                className='dots-mobile d-block d-lg-none'
                onClick={() => {
                  handleProjectClick(props.project.id, props.project.status)
                }}
              >
                &nbsp; &nbsp; &#8942;
              </h4>
            </div>
            <div className='col-md-3 d-none d-lg-block'>
              <ul className='rows'>
                <li className='amount-list'>
                  <span className='project-amount'>
                    {utils.maskValue(props.project.total_budget)} ZHX
                  </span>
                </li>

                <li className='approx-amount-list'>
                  <span
                    className='project-approx-ammount'
                    style={{
                      marginLeft:
                        props.project.total_budget > 10 ? '20px' : '0px',
                    }}
                  >
                    ~ $
                    {utils.maskValue(zhxPrice * props.project.total_budget, 5)}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </Card.Title>
        <Card.Text className='slide-card-body'>
          <div className='row'>
            <div className='col-md-12'>
              <p className='project-post-date'>
                Posted &nbsp;
                {moment(props.project.submission_deadline).format(
                  'DD MMMM yyyy'
                )}
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <p className='card-body-para'>
                {props.project.description &&
                props.project.description.length > 130
                  ? props.project.description.substring(1, 130) + '...'
                  : props.project.description}
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              {props.showCloseProjBtn && props.project.status === 'open' ? (
                <button
                  className='btn btn-closeproject'
                  style={{ float: 'right', marginTop: '-10px' }}
                  onClick={() => {
                    props.closeProject(props.project.id)
                  }}
                >
                  Close Project
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        </Card.Text>
      </Card.Body>
      <Card.Footer className='project-footer'>
        <div className='row'>
          <div className='col-lg-6 '>
            <ProjectStatus status={props.project.status} />
            <div className='float-left d-block d-lg-none'>
              <p className='project-amount mobile-amount'>
                {utils.maskValue(props.project.total_budget)} ZHX
                <span className='project-approx-ammount mobile-approx-amount'>
                  ~ ${utils.maskValue(zhxPrice * props.project.total_budget, 5)}
                </span>
              </p>
              {/* <p className="project-approx-ammount mobile-approx-amount">
                ~ $180
              </p> */}
            </div>
          </div>

          <div className='col-md-6 d-none d-lg-block'>
            <h4
              style={{ float: 'right', color: '#98A5AF' }}
              className='dots'
              onClick={() => {
                handleProjectClick(props.project.id, props.project.status)
              }}
            >
              &#8942;
            </h4>
          </div>
        </div>
      </Card.Footer>
    </Card>
  )
}

export default ProjectCard

import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAuthToken,
  updateEmail,
  updateProfileType,
} from '../../../slices/tokenSlice.js'
import * as utils from '../../../utils/utils.js'
import { ClipLoader } from 'react-spinners'
import metaMaskIcon from '../../../assests/images/metamask-icon.svg'

const LoginModal = props => {
  const emailRef = useRef('')
  const passowrd = useRef('')
  const wallet = useSelector(state => state.wallet)
  const profile = useSelector(state => state.profile)
  const dispatch = useDispatch()

  const login = async () => {
    console.log(1)
    await utils
      .connectByEmail({
        email: emailRef.current.value,
        password: passowrd.current.value,
      })
      .then(resp => {
        console.log(2)
        if (resp.success) {
          props.toggleLoginModal()
          console.log(resp.token)
          dispatch(updateEmail(resp.email))
          dispatch(updateAuthToken(resp.token))
          dispatch(updateProfileType('client'))
        } else utils.showErrorAlert('Whoops...', "Can't Login")
      })
  }

  const connectBtn = () => {
    return (
      <div
        className='meta-mask'
        style={{
          cursor: 'pointer',
          backgroundColor:
            wallet !== null && props.connected === true ? '#0084FF' : '',
        }}
        onClick={async () => {
          if (await props.connect()) props.toggleLoginModal()
        }}
      >
        <img src={metaMaskIcon} className='metamaskIcon' />

        <p
          className='metaMaskBtnTxt'
          style={{ color: wallet !== null ? '#FFFFFF' : '' }}
        >
          {props.connected === false ? (
            <ClipLoader size={30} color='#0084FF' />
          ) : wallet && profile ? (
            wallet.substr(0, 8) + '...' + wallet.substr(wallet.length - 8)
          ) : (
            'Connect Metamask'
          )}
        </p>
      </div>
    )
  }

  return (
    <Modal
      show={props.showLoginModal}
      size='sm'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          props.toggleLoginModal()
        }}
      >
        <Modal.Title id='contained-modal-title-vcenter'>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className='row'>
            <div className='col-md-12' style={{ padding: '5px' }}>
              <input
                ref={emailRef}
                type='email'
                className='form-control'
                name='email'
                placeholder='Email'
                autoComplete='username'
              />
            </div>
            <div className='col-md-12' style={{ padding: '5px' }}>
              <input
                ref={passowrd}
                type='password'
                className='form-control'
                name='passowrd'
                placeholder='Password'
                autoComplete='current-password'
              />
            </div>
          </div>
        </form>
        <div
          className='col-md-12 d-flex justify-content-center'
          style={{ padding: '5px' }}
        >
          <button className='btn btn-primary' onClick={login}>
            Login
          </button>
        </div>
        <div className='col-md-12 d-flex p-0 justify-content-center'>
          <a
            className=''
            onClick={() => {
              props.toggleLoginModal()
              props.toggleSignupModal()
            }}
          >
            Create a new account
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer className='justify-content-center'>
        <div className='col-md-12 text-center'>or</div>
        {connectBtn()}
      </Modal.Footer>
    </Modal>
  )
}

export default LoginModal

import React from 'react'
import { useHistory } from 'react-router-dom'
import './submission.css'
import { Card } from 'react-bootstrap'
import DummyProfilePic from '../../../assests/images/profile-placeholder.webp'
import fileIcon from '../../../assests/images/file-blue.svg'
import * as utils from '../../../utils/utils'
import config from '../../../config/config.json'
import { useSelector } from 'react-redux'

const Submission = ({
  submission,
  showPreviewBtn,
  showDetailsBtn,
  info,
  showPreviewFiles,
  handleProceedClick,
  status,
  showProceed = false,
}) => {
  const history = useHistory()
  const zhxPrice = useSelector(state => state.zhxPrice)

  const handlePreviewClick = () => {
    if (submission.status === '1') {
      history.push(`/projects/submission/${submission.id}/preview`)
    } else if (submission.status === '2' || submission.status === '3') {
      history.push(
        `/projects/${submission.client_request_id}/submission/${submission.id}/details`
      )
    }
  }

  const renderBtn = () => {
    if (showPreviewBtn !== false) {
      return (
        <div className='col-md-12'>
          <button
            className='btn btn-primary sub-preview-btn'
            style={{
              float: 'right',
            }}
            onClick={handlePreviewClick}
            // disabled={!requestStatus}
          >
            Preview
          </button>
        </div>
      )
    } else if (showDetailsBtn === true) {
      return (
        <div className='col-md-12'>
          <button
            className='btn btn-primary sub-details-btn'
            style={{
              float: 'right',
            }}
            onClick={handlePreviewClick}
          >
            Details
          </button>
        </div>
      )
    } else {
      return ''
    }
  }

  const renderSubmissionPreviewFiles = () => {
    if (
      showPreviewFiles === true &&
      submission.submissionAttachments.length > 0
    ) {
      return submission.submissionAttachments.map((file, index) => {
        if (file.attachment_type === 'preview') {
          return (
            <div className='row' key={index}>
              <div className='col-md-12'>
                <img src={fileIcon} />
                <a href={config.apiUrl + file.url} target='_blank'>
                  {file.name === null ? 'File' : file.name}
                </a>
              </div>
            </div>
          )
        }
      })
    }
  }

  return (
    <Card className='shadow' style={{ borderRadius: '10px' }}>
      <Card.Header
        style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
      >
        <div className='row'>
          <div className='col-8'>
            <img
              src={
                info === undefined || info.user_image === null
                  ? DummyProfilePic
                  : config.apiUrl + info.user_image
              }
              className='img-profile pull-left mr-2'
              style={{ height: '47px', width: '47px', borderRadius: '100px' }}
            />
            <p className='peron-name primary-font-color'>
              {info.username && info.username !== ' '
            ? info.username
            : info.email
            ? info.email
            : info.wallet
            ? info.wallet.substr(0, 8) +
              '...' +
              info.wallet.substr(info.wallet.length - 8)
            : 'N/A'}
            </p>
            <p className='person-designation primary-font-color'>
              {info === undefined || info.designation === null
                ? 'N/A'
                : info.designation}
            </p>
          </div>
          <div className='col-4'>
            {/* {submission.client_new_activity === 1 && (
              <div style={{ float: "right" }}>
                <Dot />
              </div>
            )} */}
            <div
              style={{ float: 'right' }}
              className='submission-amount-container'
            >
              <p className='sub-preview-amount'>
                {submission.zhx_budget === null
                  ? 0
                  : utils.maskValue(submission.zhx_budget)}
                ZHX
              </p>
              <p className='sub-preview-approx-ammount'>
                ~ $
                {submission.zhx_budget === null
                  ? 0
                  : utils.maskValue(
                      zhxPrice * submission.zhx_budget,
                      5
                    )}
              </p>
            </div>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Card.Text className='submission-desc primary-font-color'>
          {submission.description === null ? 'N/A' : submission.description}
        </Card.Text>
        {showProceed && (
          <button
            className='btn btn-proceed mx-auto my-3 px-5 py-2 d-lg-none d-block'
            onClick={() => {
              handleProceedClick(submission.request_id, submission.id)
            }}
            disabled={status == '2' ? true : false}
          >
            Proceed to Pay
          </button>
        )}
        {renderSubmissionPreviewFiles()}
        {/* {submission.filestoragelist !== undefined &&
          submission.filestoragelist.length > 0
            ? submission.filestoragelist.map((file, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="col-md-12">
                      <img src={fileIcon} />
                      <a href={file.filePath} target="_blank">
                        {file.fileName === null ? "File" : file.fileName}
                      </a>
                    </div>
                  </div>
                );
              })
            : ""} */}
        {submission.dispute_transaction_hash !== null ? (
          <div className='row'>
            <div className='col-md-12'>
              <p
                style={{
                  float: 'right',
                  marginRight: '30px',
                  color: '#EB5757',
                  fontWeight: 'bold',
                }}
              >
                Disputed
              </p>
            </div>
          </div>
        ) : (
          ''
        )}

        <div className='row'>{renderBtn()}</div>
      </Card.Body>
    </Card>
  )
}

export default Submission

import Swal from 'sweetalert2';
import SuccessImg from '../assests/images/success.svg';
import PendingImg from '../assests/images/pending.svg';
import ErrorImg from '../assests/images/error.svg';
import { UserService } from '../http-services/client/user';
import config from '../config/config.json'
import Web3 from 'web3'

export const showSuccessAlert = (title, message, callback = false) => {
  Swal.fire({
    title: title,
    imageUrl: SuccessImg,
    customClass: 'swl-custom',
    showDenyButton: false,
    showConfirmButton: true,
    confirmButtonText: 'Dismiss',
    confirmButtonColor: '#0084FF',
    html: `<p className='txtAlert'>${message}`,
    width: '371px',
  }).then(async (resp) => {
    if (callback) await callback()
  });
};

export const showPendingAlert = (title, message) => {
  Swal.fire({
    title: title,
    imageUrl: PendingImg,
    customClass: 'swl-custom',
    showDenyButton: false,
    showConfirmButton: true,
    confirmButtonText: 'Dismiss',
    confirmButtonColor: '#0084FF',
    html: `<p className='txtAlert'>${message}`,
    width: '371px',
  }).then((isConfirm) => {
  });
};

export const showErrorAlert = async (title, message, cBtn = false, txtBtn = 'Dismiss', email, callback = false) => {
  const response = await Swal.fire({
    showCloseButton: cBtn,
    title: title,
    imageUrl: ErrorImg,
    customClass: 'swl-custom',
    showDenyButton: true,
    showConfirmButton: false,
    denyButtonText: txtBtn,
    denyButtonColor: '#0084FF',
    html: `<p className='txtAlert'>${message}`,
    width: '371px',
  }).then(async (resp) => {
    if (callback && resp.isDenied) {
      const resp = await callback(email)
      if (resp.success)
        showSuccessAlert('Connected', 'Wallet is connected now')
      return resp
    }
  });
  return response
};

export const showConfirmationAlert = async (title, message, type) => {
  let result = await Swal.fire({
    title: title,
    text: message,
    icon: type,
    showCancelButton: true,
    confirmButtonColor: '#0084FF',
    cancelButtonColor: '#0084FF',
    confirmButtonText: 'Yes',
  });
  if (result.isConfirmed) {
    return true;
  } else {
    return false;
  }
};

export const maskValue = (num, dec = 2) => {
  if (num !== null) {

    num = parseFloat(num).toFixed(dec)
    const num1 = num.toString().split('.')[0]
    const num2 = num.toString().split('.')[1]
    const result = num1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + num2
    return result
  }
};

export const truncateDecimals = (num, dec = 5) => {
  const calcDec = Math.pow(10, dec);
  return Math.trunc(num * calcDec) / calcDec;
};

const httpUser = new UserService()

export const connectByEmail = async (data) => {
  let result
  await httpUser.connectByEmail(
    data,
  )
    .then(async (resp) => {
      if (resp.data.success) {
        resp.data.email = data.email
        resp.data.wallet = data.walletAddress
        resp.data.profileType = 'client'
      }
      else
        showErrorAlert(
          'Registered already',
          'This wallet is associated with some other account. Please connect with other wallet address'
        )
      result = resp.data
    })
    .catch(error => {
      console.log(error)
      result = { success: false }
    })
  return result
}
export const update_tokens_in_wallet = async (wallet) => {
  const web3 = new Web3(window.ethereum)
  const tokenContract = new web3.eth.Contract(
    config.tokenAbi,
    config.tokenContractAddr
  )
  try {
    let tokens = await tokenContract.methods.balanceOf(wallet).call()
    tokens = parseFloat(web3.utils.fromWei(tokens, 'ether'))
    return tokens
  }
    catch (e) {
    console.log(e.message)
    return 0
  }
}
export const getUserProfile = async (authToken,metamaskOrEmail) => {
  try {
    console.log('getUser1')
    if (authToken) {
      let response = await httpUser.getUser(metamaskOrEmail, authToken)
      // console.log(response)
      if (response.status === 200) {
        console.log('getUser2')
        return response.data.result
      }
    }
  } catch (error) {
    console.log('Error while fetching profile ', error.response)
  }
}
export const isWalletValid = async (email) => {
  let isConnected = false
  if (window.ethereum) {
    console.log('Metamask connected')
    window.web3 = new Web3(window.ethereum)
    isConnected = true
    // await window.ethereum.enable()
  } else if (window.web3) {
    console.log('Metamask connected')
    window.web3 = new Web3(window.web3.currentProvider)
    isConnected = true
  } else {
    console.log('Metamask not installed')
    isConnected = false
  }

  if (isConnected) {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const mainAccount = accounts[0]
    return connectByEmail({
      email: email,
      walletAddress: mainAccount,
    })
  } else {
    showErrorAlert('Whoops...', 'Metamask is not installed')
    return { success: false }
  }
}
import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import Submission from '../../../components/client/submission/submission'
import Footer from '../../../components/shared/Footer/Footer'
import DummyProfilePic from '../../../assests/images/profile-placeholder.webp'
import Web3 from 'web3'
import config from '../../../config/config.json'
import './submission-preview.css'
import { SubmissionService } from '../../../http-services/client/submission'
import { FadeLoader } from 'react-spinners'
import { Empty } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import * as utils from '../../../utils/utils.js'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateZhxTokens,
  updateProfileType,
  updateWalletAdd,
  updateProfile,
} from '../../../slices/tokenSlice'

const SubmissionPreview = props => {
  const httpSubmission = new SubmissionService()
  const [submissionDetails, setSubmissionDetails] = useState(null)
  const [submitter_info, setSubmitter_info] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const dispatch = useDispatch()
  const authToken = useSelector(state => state.authToken)
  const profileType = useSelector(state => state.profileType)
  const wallet = useSelector(state => state.wallet)
  const email = useSelector(state => state.email)

  useEffect(() => {
    if (authToken && profileType !== 'client') {
      sessionStorage.setItem('currentTab', 1)
      dispatch(updateProfileType('client'))
    }
    let submissionId = props.match.params.submissionId
    previewSubmission(submissionId)
  }, [])
  const previewSubmission = async id => {
    try {
      if (id && authToken) {
        let resp = await httpSubmission.previewSubmission(id, authToken, false)
        setSubmissionDetails(resp.data.result.submissionDetail)
        setSubmitter_info(resp.data.result.service_provider)
        setLoaded(true)
      }
    } catch (error) {
      console.log('Error in preview submission: ', error)
      setSubmissionDetails(null)
      setSubmitter_info(null)
      setLoaded(null)
    }
  }

  const renderSubmissionPreview = () => {
    if (loaded === false) {
      return (
        <FadeLoader
          css={{
            display: 'block',
            margin: '0 auto',
            borderColor: 'red',
            color: 'red',
          }}
          size={550}
        />
      )
    } else if (loaded === true && submissionDetails !== null) {
      return (
        <div className='row' style={{ marginBottom: '20px' }}>
          <div className='col-lg-9 px-0 px-lg-3'>
            <Submission
              showPreviewBtn={false}
              submission={submissionDetails}
              info={submitter_info}
              showPreviewFiles={true}
              handleProceedClick={handleProceedClick}
              status={submissionDetails.status}
              showProceed={true}
            />
          </div>
          <div className='col-lg-3'>
            <div className='btn-row'>
              <button
                className='btn btn-proceed w-100 py-3 d-none d-lg-block'
                onClick={() => {
                  handleProceedClick(
                    submissionDetails.client_request_id,
                    submissionDetails.id
                  )
                }}
                disabled={!submissionDetails.status}
              >
                Proceed to Pay
              </button>
              <hr className='d-none d-lg-block' />
            </div>
            <p id='profile-heading' className='mt-5 mt-lg-0'>
              Service Provider Profile
            </p>
            <div className='d-flex align-items-center'>
              <img
                src={
                  submitter_info.user_image === null ||
                  submitter_info.user_image === 'NA'
                    ? DummyProfilePic
                    : config.apiUrl + submitter_info.user_image
                }
                style={{
                  height: '75px',
                  width: '75px',
                  borderRadius: '100px',
                }}
              />
              <div className='profileBox d-flex flex-column justify-content-around ml-3'>
                <div className='profile-name'>
                  {submitter_info.username && submitter_info.username !== ' '
                    ? submitter_info.username
                    : submitter_info.email
                    ? submitter_info.email
                    : submitter_info.wallet
                    ? submitter_info.wallet.substr(0, 8) +
                      '...' +
                      submitter_info.wallet.substr(
                        submitter_info.wallet.length - 8
                      )
                    : 'N/A'}
                </div>
                <div className='profile-join-date'>
                  Joined &nbsp;
                  {moment(submissionDetails.created_timestamp).format(
                    'DD MMM yyyy'
                  )}
                </div>
                <div className='profile-total-projects'>
                  Total Project:
                  {submissionDetails.total_projects === null
                    ? 0
                    : submissionDetails.total_projects}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return <Empty />
    }
  }

  const handleProceedClick = async (requestId, submissionId) => {
    let _projectID = submissionDetails.client_request_id
    let _projectAmount = submissionDetails.zhx_budget
    let _serviceProvider = submissionDetails.address
    console.log('PROCEED ID PARAM: ', _projectID)
    console.log('PROCEED AMOUNT PARAM: ', _projectAmount)
    console.log('PROCEED ADDRESS PARAM: ', _serviceProvider)

    const web3 = new Web3(window.ethereum)
    const marketContract = new web3.eth.Contract(
      config.marketPlaceAbi,
      config.marketPlaceContractAddr
    )

    const tokenContract = new web3.eth.Contract(
      config.tokenAbi,
      config.tokenContractAddr
    )
    console.log('TOken contract: ', tokenContract)

    if (!wallet) {
      const resp = await utils.showErrorAlert(
        'No wallet connected',
        'Please connect your wallet to proceed',
        true,
        'Connect Wallet',
        email,
        utils.isWalletValid
      )
      if (resp && resp.success) {
        const metamaskOrEmail = email ? email : wallet
        const profile = await utils.getUserProfile(authToken, metamaskOrEmail)
        dispatch(updateProfile(profile))
        const tokens = await utils.update_tokens_in_wallet(resp.wallet)
        dispatch(updateWalletAdd(resp.wallet))
        dispatch(updateZhxTokens(tokens))
      }
      return
    } else if (email) {
      const resp = await utils.isWalletValid(email)
      if (resp && resp.success) {
        const metamaskOrEmail = email ? email : wallet
        const profile = await utils.getUserProfile(authToken, metamaskOrEmail)
        dispatch(updateProfile(profile))
        const tokens = await utils.update_tokens_in_wallet(resp.wallet)
        dispatch(updateWalletAdd(resp.wallet))
        dispatch(updateZhxTokens(tokens))
      } else return
    }
    try {
      await tokenContract.methods
        .approve(
          config.marketPlaceContractAddr,
          web3.utils.toWei(_projectAmount.toString(), 'ether')
        )
        .send({
          from: wallet,
          gasPrice: web3.utils.toWei('56', 'gwei'),
        })
        .on('transactionHash', hash => {
          utils.showPendingAlert('Please Wait', 'Wallet is approving')
        })
        .on('receipt', async ap_reciept => {
          utils.showSuccessAlert(
            'Approved',
            `Wallet is approved! <br/> <a href=${
              config.bscanUrl + ap_reciept.transactionHash
            } target='_blank'>Click here to view transaction</a>.`
          )
          await marketContract.methods
            .publishProject(
              _projectID,
              web3.utils.toWei(_projectAmount.toString(), 'ether'),
              _serviceProvider
            )
            .send({
              from: wallet,
              gasPrice: web3.utils.toWei('56', 'gwei'),
            })
            .on('transactionHash', async hash => {
              utils.showPendingAlert('Transaction', 'Transaction is pending')
            })
            .on('receipt', async receipt => {
              utils.showSuccessAlert(
                'Success',
                `Transaction successfull! <br/> <a href=${
                  config.bscanUrl + receipt.transactionHash
                } target='_blank'>Click here to view transaction</a>.`
              )
              const tokens = await utils.update_tokens_in_wallet(wallet)
              dispatch(updateZhxTokens(tokens))

              let isServiceProvider =
                profileType === 'client' || profileType === null ? false : true
              let data = {
                isServiceProvider: isServiceProvider,
                submission_id: submissionDetails.id,
                status: 2,
                transaction_hash: receipt.transactionHash,
              }

              await httpSubmission.changeStatus(data, authToken)
              props.history.push(
                `/projects/${requestId}/submission/${submissionId}/details`
              )
            })
            .on('error', async error => {
              console.log('error', error)
              utils.showErrorAlert('Whoops..', 'Transaction Unsuccessfull!')
            })
        })
    } catch (e) {
      utils.showErrorAlert('Whoops..', 'Transaction Unsuccessfull!')
      console.log('error rejection', e)
    }
  }
  return (
    <>
      <div
        className='container-fluid'
        style={{ backgroundColor: '#F8F9FD', padding: '0px' }}
      >
        <div className='container container-main'>
          <div className='row' style={{ paddingTop: '30px' }}>
            <div className='mx-3'>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to='/projects'>My Projects</Link>
                </Breadcrumb.Item>
                {submissionDetails !== null ||
                submissionDetails !== null ||
                submissionDetails !== null ? (
                  <>
                    <Breadcrumb.Item>
                      <Link to={`/projects/${submissionDetails.id}/details`}>
                        {submissionDetails.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                      active
                      // href={`/projects/submission/${props.match.params.submissionId}/preview`}
                    >
                      <Link
                        to={`/projects/submission/${props.match.params.submissionId}/preview`}
                      >
                        Submission Preview
                      </Link>
                    </Breadcrumb.Item>
                  </>
                ) : (
                  ''
                )}
              </Breadcrumb>
            </div>
          </div>
          {renderSubmissionPreview()}
        </div>

        <div className='container-fluid'>
          <div className='row'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default SubmissionPreview

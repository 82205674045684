import React, { useEffect, useState } from 'react'
import ProjectCard from '../../../components/client/project-card/project'
import Footer from '../../../components/shared/Footer/Footer'
import FuzzySearch from 'fuzzy-search'
import { ProjectRequestService } from '../../../http-services/client/request'
import { FadeLoader } from 'react-spinners'
import { Empty } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfileType } from '../../../slices/tokenSlice'

const Offerings = () => {
  const [lstProjects, setLstProjects] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [loaded, setLoaded] = useState(false)
  const authToken = useSelector(state => state.authToken)
  const profileType = useSelector(state => state.profileType)
  const dispatch = useDispatch()
  
  
  useEffect( () => {
    const getAllProjects = async () => {
      const httpProjectRequest = new ProjectRequestService()
      try {
        setLoaded(false)
        let resp = await httpProjectRequest.showAllOffering(authToken)
        let projects = resp.data.result
        let totalRows = Math.round(projects.length / 2)
        let arr = []
        let count = 0
        for (let i = 0; i < totalRows; i++) {
          let _projects = []
          _projects.push(projects[count])
          _projects.push(projects[count + 1])
          arr.push({
            projects: _projects,
          })
          count += 2
        }
        setLstProjects(resp.data)
        setFilteredProjects(arr)
        setLoaded(true)
      } catch (error) {
        setLstProjects([])
        setFilteredProjects([])
        setLoaded(true)
      }
    }
    if (authToken && profileType !== 'service_provider') {
      sessionStorage.setItem('currentTab', 1)
      dispatch(updateProfileType('service_provider'))
    }
    if (authToken)  getAllProjects()
    else setLoaded(true)
  }, [authToken,profileType,dispatch])

 

  

  const handleSearchClick = () => {
    const searcher = new FuzzySearch(
      lstProjects.offering_list,
      ['title', 'state'],
      {
        caseSensitive: false,
      }
    )
    const result = searcher.search(searchValue)
    let totalRows = Math.round(result.length / 2)
    let arr = []
    let count = 0
    for (let i = 0; i < totalRows; i++) {
      let _projects = []
      _projects.push(result[count])
      _projects.push(result[count + 1])
      arr.push({
        projects: _projects,
      })
      count += 2
    }
    console.log('ARR: ', arr)
    setFilteredProjects(arr)
    setLoaded(true)
  }
  const onSearchChange = e => {
    setSearchValue(e.target.value)
  }
  const handleEnterKey = e => {
    if (e.keyCode === 13) {
      handleSearchClick()
    }
  }

  const renderAllProjects = () => {
    if (loaded === true && filteredProjects.length > 0) {
      return (
        <>
          <div
            className='row'
            style={{ marginBottom: '30px', marginTop: '30px' }}
          >
            <div className='col-md-12'>
              <div className='input-group has-search'>
                <span className='fa fa-search form-control-feedback'></span>
                <input
                  type='text'
                  className='form-control searchField'
                  placeholder='Search'
                  onChange={onSearchChange}
                  onKeyDown={handleEnterKey}
                ></input>
                <div className='input-group-append'>
                  <button
                    className='btn btn-secondary searchFieldBtn'
                    type='button'
                    onClick={handleSearchClick}
                  >
                    <i className='fa fa-search'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {filteredProjects.length > 0
            ? filteredProjects.map((row, i) => {
                return (
                  <div className='row mb-0 mb-lg-3' key={i}>
                    {row.projects.map((project, index) => {
                      if (project !== undefined) {
                        return (
                          <div
                            className='col-md-6 px-0 px-lg-3 mb-3 mb-lg-0'
                            key={index}
                          >
                            <ProjectCard
                              project={project}
                              // notification={project.service_provider_new_activity}
                              showCloseProjBtn={false}
                            />
                          </div>
                        )
                      }
                      return ''
                    })}
                  </div>
                )
              })
            : ''}
        </>
      )
    } else if (loaded === true && filteredProjects.length === 0) {
      return <Empty />
    } else {
      return (
        <FadeLoader
          css={{
            display: 'block',
            margin: '0 auto',
            borderColor: 'red',
            color: 'red',
          }}
          size={550}
        />
      )
    }
  }

  return (
    <>
      <div
        className='container-fluid'
        style={{ backgroundColor: '#E5E5E5', padding: '0px' }}
      >
        <div className='container container-main'>{renderAllProjects()}</div>

        <div className='container-fluid'>
          <div className='row'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default Offerings

import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import * as utils from '../../../utils/utils.js'
import socket from '../../../socket'
import { updateAuthToken, updateEmail } from '../../../slices/tokenSlice.js'
import { useDispatch, useSelector } from 'react-redux'

const SignupModal = props => {
  const firstName = useRef('')
  const lastName = useRef('')
  const email = useRef('')
  const passowrd = useRef('')
  const designation = useRef('')
  const profileType = useSelector(state => state.profileType)
  const dispatch = useDispatch()

  const signup = async () => {
    const resp = await utils.connectByEmail({
      email: email.current.value,
      password: passowrd.current.value,
      first_name: firstName.current.value,
      last_name: lastName.current.value,
      designation: designation.current.value,
    })
    if (resp.success) {
      props.toggleSignupModal()
      console.log(resp.token)
      dispatch(updateAuthToken(resp.token))
      dispatch(updateEmail(resp.email))
      if (props.user) {
        socket.emit('join-room', `notify${props.user.user_id}`)
        socket.emit(
          'get-notifications',
          { user_id: props.user.user_id, profileType },
          resp => {
            console.log('NOTIFICATION COUNT', resp)
            props.setNumberOfNotifications(resp)
          }
        )
      }
    } else utils.showErrorAlert('Whoops...', "Can't Login")
  }
  return (
    <Modal
      show={props.showSignupModal}
      size='sm'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          props.toggleSignupModal()
        }}
      >
        <Modal.Title id='contained-modal-title-vcenter'>Sign up</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className='row'>
            <div className='col-md-6' style={{ padding: '5px' }}>
              <input
                ref={firstName}
                type='text'
                className='form-control'
                name='firstName'
                placeholder='First Name'
                required
              />
            </div>
            <div className='col-md-6' style={{ padding: '5px' }}>
              <input
                ref={lastName}
                type='text'
                className='form-control'
                name='lastName'
                placeholder='Last Name'
                required
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12' style={{ padding: '5px' }}>
              <input
                ref={designation}
                type='text'
                className='form-control'
                name='designation'
                placeholder='Designation'
              />
            </div>
            <div className='col-md-12' style={{ padding: '5px' }}>
              <input
                ref={email}
                type='email'
                className='form-control'
                name='email'
                placeholder='Email'
                autoComplete='usename'
                required
              />
            </div>
            <div className='col-md-12' style={{ padding: '5px' }}>
              <input
                ref={passowrd}
                type='password'
                className='form-control'
                name='passowrd'
                placeholder='New Password'
                autoComplete='new-password'
                required
              />
            </div>
          </div>
        </form>
        <div
          className='col-md-12 d-flex justify-content-center'
          style={{ padding: '5px' }}
        >
          <button className='btn btn-primary' onClick={signup}>
            Sign up
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SignupModal

import React, { useEffect, useState } from 'react'
import Feed from '../../../components/service-provider/feed/feed'
import Footer from '../../../components/shared/Footer/Footer'
import { ProjectRequestService } from '../../../http-services/client/request'
import { FadeLoader } from 'react-spinners'
import { Empty } from 'antd'
import FuzzySearch from 'fuzzy-search'
import Pagination from 'react-js-pagination'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfileType } from '../../../slices/tokenSlice'

const Feeds = () => {
  const [feeds, setFeeds] = useState(null)
  const [filteredFeeds, setFilteredFeeds] = useState([])
  const [currPageFeeds, setCurrPageFeeds] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [activePage, setActivePage] = useState(1)
  const authToken = useSelector(state => state.authToken)
  const profileType = useSelector(state => state.profileType)
  const email = useSelector(state => state.email)
  const wallet = useSelector(state => state.wallet)
  const dispatch = useDispatch()

  const httpProjectRequest = new ProjectRequestService()
  useEffect(() => {
    if (authToken && profileType !== 'service_provider') {
      sessionStorage.setItem('currentTab', 1)
      dispatch(updateProfileType('service_provider'))
    }
    if (feeds === null) getAllFeeds()
  }, [])
  const getAllFeeds = async () => {
    if (authToken ) {
      try {
        let resp = await httpProjectRequest.showAllRequest(
          true,
          authToken,
          email,
          wallet
        )
        let feeds = resp.data.result
        console.log('Feeds: ', feeds)
        setFeeds(feeds)
        setLoaded(true)
        setFilteredFeeds(feeds)
      } catch (error) {
        setFeeds([])
        setLoaded(true)
      }
    } else {
      setFeeds([])
      setLoaded(true)
    }
  }

  const searchLogic = () => {
    if (searchValue === '') {
      setFilteredFeeds(feeds)
    } else {
      const searcher = new FuzzySearch(feeds, ['title', 'state'], {
        caseSensitive: false,
      })
      const result = searcher.search(searchValue)
      setFilteredFeeds(result)
    }
  }

  const onSearchChange = e => {
    setSearchValue(e.target.value)
  }

  const handleEnterKey = e => {
    if (e.keyCode === 13) {
      searchLogic()
    }
  }

  const handlePageChange = pageNumber => {
    console.log(`active page is ${pageNumber}`)
    setActivePage(pageNumber)
  }

  const renderFeeds = () => {
    if (loaded === false) {
      return (
        <FadeLoader
          css={{
            display: 'block',
            margin: '0 auto',
            borderColor: 'red',
            color: 'red',
          }}
          size={550}
        />
      )
    } else if (loaded === true && filteredFeeds.length > 0) {
      return filteredFeeds.map((feed, index) => {
        return (
          <div className='row' key={index}>
            <div className='col-lg-12 px-0 px-lg-3'>
              <Feed feed={feed} />
            </div>
          </div>
        )
      })
    } else {
      return <Empty />
    }
  }
  return (
    <>
      <div
        className='container-fluid'
        style={{ backgroundColor: '#E5E5E5', padding: '0px' }}
      >
        <div className='container container-main'>
          <div className='row'>
            <div className='col-md-12'>
              <div
                className='input-group has-search'
                style={{ marginTop: '20px', marginBottom: '20px' }}
              >
                <span className='fa fa-search form-control-feedback'></span>
                <input
                  type='text'
                  className='form-control searchField'
                  placeholder='Search'
                  onChange={onSearchChange}
                  onKeyDown={handleEnterKey}
                ></input>
                <div className='input-group-append'>
                  <button
                    className='btn btn-secondary searchFieldBtn'
                    type='button'
                    onClick={() => {
                      searchLogic()
                    }}
                  >
                    <i className='fa fa-search'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {renderFeeds()}
          <div className='row'>
            <div className='col-md-12'>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={filteredFeeds.length}
                pageRangeDisplayed={5}
                itemClass='page-item'
                linkClass='page-link'
                onChange={handlePageChange.bind(this)}
              />
            </div>
          </div>
        </div>

        <div className='container-fluid'>
          <div className='row'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default Feeds

import React from "react";
import Advertisement from "../../components/client/advertisement/Advertisement";
import Banner from "../../components/client/main-banner/banner";
import PostingBanner from "../../components/client/posting-banner/PostingBanner";
import ServicesCarousel from "../../components/client/services-carousel/services-carousel";
import Services from "../../components/client/services/services";
import Footer from "../../components/shared/Footer/Footer";
import HomeBg from "../../assests/images/HomeBg.webp"

const LandingPage = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Banner />
        </div>
        <img src={HomeBg} alt="vector" className="homeBg position-absolute" />
        <div className="row">
          <div className="col-md-12">
            <Services />
          </div>
        </div>
      </div>
      <div className="container-fluid p-0 overflow-hidden">
        <div
          className="row"
          style={{ height: "auto", marginBottom: "100px" }}
        >
          <div className="col-md-12 p-0">
            <ServicesCarousel />
          </div>
        </div>
      </div>
      <div className="container-fluid px-lg-5">
        <div className="row">
          <div className="col-md-12">
            <Advertisement />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-xl-12">
            <PostingBanner />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LandingPage;

import axios from 'axios';
import config from '../../config/config.json';

export class SubmissionService {
  insertSubmission = (data, authToken) => {
    try {
      let resp = axios.post(
        `${config.apiUrl}/requests/submission_insert`,
        data,
        {
          headers: {
            'x-access-token': authToken,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in insertSubmission Http request', error);
      return null;
    }
  };

  previewSubmission = (submissionId, authToken, isServiceProvider) => {
    try {
      const resp = axios.get(
        `${config.apiUrl}/requests/get_submission_detail/${submissionId}/${isServiceProvider}`,
        {
          headers: {
            'x-access-token': authToken,
          },
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in previewSubmission Http request', error);
      return null;
    }
  };

  insertSubmissionMessage = (data, authToken) => {
    try {
      let resp = axios.post(
        `${config.apiUrl}/requests/send_message`,
        data,
        {
          headers: {
            'x-access-token': authToken,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in insertSubmissionMessage Http request', error);
      return null;
    }
  };
  insertRevision = (data, authToken) => {
    try {
      let resp = axios.post(
        `${config.apiUrl}/requests/revision`,
        data,
        {
          headers: {
            'x-access-token': authToken,
          },
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in insertRevision Http request', error);
      return null;
    }
  };

  generateInvoice = (data, authToken) => {
    try {
      let resp = axios.get(
        `${config.apiUrl}/requests/generate_invoice/${data}`,
        {
          headers: {
            'x-access-token': authToken,
          }
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in generateInvoice Http request', error);
      return null;
    }
  };

  changeStatus = (data, authToken) => {
    try {
      let resp = axios.put(
        `${config.apiUrl}/requests/submission_update`,
        data,
        {
          headers: {
            'x-access-token': authToken,
          },
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in changeStatus Http request', error);
      return null;
    }
  };
  changeRevisionStatus = (data, authToken) => {
    try {
      let resp = axios.put(
        `${config.apiUrl}/requests/revision_update`,
        data,
        {
          headers: {
            'x-access-token': authToken,
          },
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in changeRevisionStatus Http request', error);
      return null;
    }
  };

  deliverAgain = (data, authToken) => {
    try {
      let resp = axios.post(
        `${config.apiUrl}/requests/deliver`,
        data,
        {
          headers: {
            'x-access-token': authToken,
          },
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in deliverAgain Http request', error);
      return null;
    }
  };

  markSubmissionDisputed = (data, authToken) => {
    try {
      let resp = axios.post(
        `${config.apiUrl}/requests/mark_submission_disputed`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in markSubmissionDisputed Http request', error);
      return null;
    }
  };
}
